<template>
    <div
        class="builder-right-templates"
        :style="{ width: width + 'px', minWidth: width + 'px' }"
        v-if="isOpen"
        v-click-outside="close"
    >
        <div class="builder-right-templates__content">
            <div class="builder-right-templates__filters">
                <SelectMultiple
                    :return-object="false"
                    key="templates"
                    :placeholder="$t('settings.header.templateFilter')"
                    v-model="activeFilters"
                    :items="filterItems"
                ></SelectMultiple>
            </div>
            <div
                class="builder-right-templates__item"
                v-for="(item, index) in filtered"
                :class="{ '-selected': item.title === selected }"
                :key="item.title"
                :title="item.title"
                @click="handleClick(item)"
            >
                <img
                    class="builder-right-templates__preview"
                    :loading="index > 20 ? 'lazy' : null"
                    :decoding="index > 20 ? 'async' : null"
                    :alt="item.title"
                    v-if="item.preview"
                    :src="item.preview"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EventEmitter from '@/helpers/eventEmitter';
import SelectMultiple from '@/components/form/controls/SelectMultiple';

export default {
    name: 'builder-right-templates',
    components: { SelectMultiple },

    data() {
        return {
            isOpen: false,
            allowClose: true,
            items: [],
            images: {},
            filterItems: [],
            activeFilters: [],
            name: '',
            width: 340,
            selected: null,
        };
    },

    created() {
        EventEmitter.on('toggle-templates', (isOpen, items, name, width, value) => {
            this.width = width;
            const selected = items.find((item) => item.title === value);
            this.selected = selected ? selected.title : null;
            this.items = selected ? [selected, ...items.filter((item) => item.title !== selected.title)] : [...items];
            this.isOpen = isOpen;
            if (this.name && this.name !== name) {
                EventEmitter.trigger(`close-templates:${this.name}`);
                this.activeFilters = [];
            }
            //костыль, чтобы не тригерить сразу обработчик click-outside
            this.allowClose = false;
            setTimeout(() => (this.allowClose = true), 500);
            if (this.isOpen) {
                const filterItems = items.reduce((acc, item) => {
                    if (item.tags) {
                        acc.push(...item.tags);
                    }
                    return acc;
                }, []);
                this.filterItems = [...new Set(filterItems)];
            }
            this.name = name;
        });
    },

    computed: {
        filtered() {
            if (!this.activeFilters.length) {
                return this.items;
            }
            return this.items.filter((item) => {
                if (!item.tags) return false;
                return this.activeFilters.every((tag) => item.tags.includes(tag));
            });
        },
    },

    methods: {
        handleClick(item) {
            EventEmitter.trigger(`select-template:${this.name}`, item);
        },

        close() {
            if (this.isOpen && this.allowClose) {
                EventEmitter.trigger(`close-templates:${this.name}`);
                this.isOpen = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.builder-right-templates {
    position: absolute;
    top: 1px;
    right: 100%;
    bottom: 0;
    display: flex;
    z-index: 1000;
    flex-direction: column;
    min-width: 340px;
    overflow: auto;
    background: var(--v-surface-base);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    padding: 24px 16px;
    direction: rtl;
    &__content {
        direction: ltr;
    }
    &__item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 48px;
        align-items: center;
        padding: 24px 12px;
        position: relative;
        transition: $transition-fast;

        &:after {
            content: '';
            display: block;
            width: 80%;
            border-top: 1px solid var(--v-outline-base);
            position: absolute;
            bottom: -24px;
        }
        &:hover {
            background-color: var(--v-primary-lighten-base);
            cursor: pointer;
        }
        &.-selected {
            background-color: var(--v-primary-lighten-base);
            pointer-events: none;
        }
    }
    &__item-title {
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &__preview {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    }
}
</style>
