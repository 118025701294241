<template>
    <div v-click-outside="closeDropdown" @click="activate" class="select-multiple" :class="classes">
        <div class="select-multiple__content">
            <template>
                <div v-for="item in selected" :key="item.id ? item.id : item" class="select-multiple__chip chip">
                    {{ item.text }}
                    <span @click.prevent.stop="removeOption(item)" class="chip__icon material-symbols-outlined">
                        close
                    </span>
                </div>
            </template>
            <input
                v-show="isActive || !selected.length"
                class="select-multiple__field"
                v-model="searchString"
                :class="title.type"
                :placeholder="placeholder"
                ref="input"
                @input="handleInput"
            />
            <span
                class="select-multiple__arrow material-symbols-outlined"
                @click.stop="isDropdownShow ? closeDropdown($event) : activate($event)"
                :class="{ 'is-open': isDropdownShow }"
            >
                expand_more
            </span>
        </div>
        <div class="select-multiple__dropdown" :class="{ 'is-open': isDropdownShow, '-top': dropdownDirectionTop }">
            <div
                v-for="option in searched"
                :key="option.id"
                class="select-multiple__option"
                :class="{ '-selected': checkIfSelected(option) }"
                @click="toggleOption(option)"
            >
                <span v-if="checkIfSelected(option)" class="icon">
                    check_box
                </span>
                <span v-else class="icon -outlined">
                    check_box_outline_blank
                </span>
                <div class="option__text" v-html="highlight(option.text)"></div>
            </div>
            <div class="select-multiple__option" v-if="!searched.length">{{ $t('search.noData') }}</div>
        </div>
        <progress-linear v-if="isLoading"></progress-linear>
    </div>
</template>

<script>
import { proxyModelMixin } from '@/mixins/proxyModelMixin';
import { cloneDeep, isEqual } from 'lodash';
import { uuid } from 'vue-uuid';
import ProgressLinear from '@/components/common/ProgressLinear';

export default {
    name: 'SelectMultiple',
    inheritAttrs: false,
    mixins: [proxyModelMixin],
    components: { ProgressLinear },
    props: {
        name: { type: String, default: '' },
        group: { type: String, default: null },
        placeholder: { type: String, default: 'Choose' },
        errors: { type: [Array, Object], default: () => [] },
        items: { type: [Array], default: () => [] },
        value: { type: [Array, String, Number] },
        itemValue: { type: [String, Function], default: 'value' },
        itemText: { type: [String, Function], default: 'text' },
        returnObject: { type: Boolean, default: true },
        hasSearch: { type: Boolean, default: false },
        isLoading: { type: Boolean, default: false },
    },

    data() {
        return {
            dropdownDirectionTop: false,
            isDropdownShow: false,
            options: [],
            selected: [],
            isActive: false,
            searchString: '',
            isDebouncing: false,
        };
    },
    computed: {
        classes() {
            return {
                '-is-open': this.isDropdownShow,
            };
        },
        searched() {
            if (!this.searchString || this.hasSearch) return this.options;
            return this.options.filter((item) => {
                return item.text.toLowerCase().indexOf(this.searchString.toLowerCase()) >= 0;
            });
        },
        title() {
            // как бы тут не было значения 0, но пока оставлю
            const selectedItem = this.options.find((option) => this.value == option.value);
            if (selectedItem) return { type: '-value', text: this.$t(selectedItem.text) };
            if (this.placeholder) return { type: '-placeholder', text: this.$t(this.placeholder) };
            return { type: '-placeholder', text: this.name };
        },
    },

    methods: {
        handleInput(e) {
            this.$emit('update-search-string', e.target.value);
        },
        highlight(text) {
            if (!this.searchString) {
                return text;
            }
            return text.replace(new RegExp(this.searchString, 'gi'), (match) => {
                return '<span class="highlight">' + match + '</span>';
            });
        },
        checkIfSelected(option) {
            return this.selected.find((item) => item.id === option.id);
        },

        activate() {
            if (!this.isActive) {
                this.isActive = true;
                this.$nextTick(() => this.$refs.input.focus());
                if (!this.isDropdownShow) {
                    this.openDropdown();
                }
            }
        },
        formatItems(options) {
            if (!options) return [];
            return options
                .map((item) => ({
                    id: uuid.v4(),
                    text: typeof this.itemText === 'function' ? this.itemText(item) : item[this.itemText] || item,
                    value: typeof this.itemValue === 'function' ? this.itemValue(item) : item[this.itemValue] || item,
                    item: cloneDeep(item),
                }))
                .sort((a, b) => a.text.localeCompare(b.text));
        },

        formatSelected(value) {
            if (!value) return [];
            const optionsToSearch = cloneDeep(this.options);
            return value.map((item) => {
                let searchedIndex;
                let searchedOption;
                optionsToSearch.forEach((option, index) => {
                    if (isEqual(option.value, item)) {
                        searchedOption = option;
                        searchedIndex = index;
                    }
                });
                optionsToSearch.splice(searchedIndex, 1);
                return searchedOption;
            });
        },

        toggleOption(option) {
            if (this.selected.find((item) => item.id === option.id)) {
                this.removeOption(option);
            } else {
                this.selectOption(option);
            }
            this.$refs.input.focus();
        },

        removeOption(option) {
            if (this.returnObject) {
                this.model = this.model.filter((item) => option.item.id !== item.id);
            } else {
                this.model = this.model.filter((value) => {
                    return option.value !== value;
                });
            }
            this.selected = this.selected.filter((item) => option.id !== item.id);
        },

        selectOption(option) {
            if (this.returnObject) {
                this.model = this.model ? [...this.model, option.item] : [option.item];
            } else {
                this.model = this.model ? [...this.model, option.value] : [option.value];
            }
            this.selected = [...this.selected, option];
        },

        openDropdown() {
            this.chooseDropdownDirection();
            this.isDropdownShow = true;
        },
        closeDropdown() {
            if (this.isDropdownShow) {
                this.isDropdownShow = false;
                this.searchString = '';
                this.isActive = false;
            }
        },
        chooseDropdownDirection() {
            const offsetTop = this.$refs.input.getBoundingClientRect().y;
            this.dropdownDirectionTop = window.innerHeight - offsetTop < 350;
        },
    },
    watch: {
        value: function(newValue) {
            if (this.hasSearch) return;
            this.$emit('update-field', {
                name: this.name,
                group: this.group,
                value: newValue,
            });
        },
        items: {
            handler(newValue) {
                this.options = this.formatItems(newValue);
                this.selected = this.formatSelected(this.value);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.select-multiple {
    position: relative;

    padding: 0 $form-control-padding-horizontal;
    border: $form-control-border;
    border-radius: $form-control-border-radius;
    background: $form-control-bg;
    transition: $form-control-transition;

    .icon {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48 !important;
    }

    .option__text {
        padding-left: 15px;
        .highlight {
            background-color: var(--v-on-primary-medium-base);
        }
    }

    input {
        border: none;
    }

    &__content {
        min-height: 38px;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        padding: 7px 0;
        margin-right: 25px;
    }

    &:hover,
    &:focus {
        border-color: $form-control-border-color-hover;
    }
    &.-is-open {
        z-index: 5;
    }
    cursor: pointer;

    &__field {
        display: block;
        height: 24px;
        outline: none !important;
        appearance: none;
        flex-shrink: 2;
        line-height: calc(#{$form-control-height} - 2px);
        &.-placeholder {
            color: inherit;
            opacity: 0.7;
        }
    }

    &__arrow {
        position: absolute;
        right: 8px;
        top: 9px;
        translate: $transition-fast;
        //pointer-events: none;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        display: block;
        width: 100%;
        border: 1px solid #cececf;
        border-radius: $input-border-radius;
        padding: 6px 0;
        background: $input-bg;
        max-height: 300px;
        overflow-y: auto;

        @include smooth-dropdown-hide();

        &.is-open {
            @include smooth-dropdown-show();
        }

        &.-top {
            bottom: 100%;
            top: auto;
            transform-origin: 0% 100%;
        }
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 40px 8px 12px;
        transition: $transition-fast;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
        cursor: pointer;

        &.-selected {
            background-color: var(--v-primary-lighten-base);
        }
        &-icon {
            position: relative;
            top: 0;
            color: inherit;
            line-height: 24px;
            margin-right: 10px;
        }

        &:hover {
            background-color: #f4f6f7;
        }

        &.-current {
            border-bottom: 1px solid #e0e0e0;
        }

        &.-highlighted {
            color: #2979ff;
        }
    }

    &__chip {
        margin: 0 4px 0 4px;
        border-radius: 12px;
        height: 24px !important;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87) !important;
        transition-property: box-shadow, opacity, -webkit-box-shadow, background;
        background: #e6e8eb !important;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 20px;
        max-width: 100%;
        outline: none;
        padding: 0 12px;
        position: relative;
        text-decoration: none;
        transition-duration: 0.28s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        white-space: nowrap;
    }
    .progress-linear {
        bottom: 0;
        left: 0;
        height: 2px;
        border-radius: 0 0 4px 4px;
    }
    .icon {
        color: var(--v-primary-base) !important;
    }
}

.chip {
    &__icon {
        font-size: 17px;
        display: block;
        margin-left: 5px;
        &:hover {
            opacity: 0.5;
        }
    }
}
</style>
